import React from "react"
import { graphql } from "gatsby"
import { mergePrismicPreviewData } from "gatsby-source-prismic"
import Layout from "../components/layout"
import SEO from "../components/seo"

import SliceZone from "../components/sliceZone"
import PageTransition from "../components/transitions/pageTransition"
import { PitchFooter, DeckNav, EmailMask } from "../components/pitchDecks"

const IS_BROWSER = typeof window !== "undefined"

const DigitalPitchDeck = ({ data: staticData }) => {
  const previewData = IS_BROWSER && window.__PRISMIC_PREVIEW_DATA__
  const mergedData = mergePrismicPreviewData({ staticData, previewData })
  const { uid } = mergedData.prismicDigitalPitchDeck
  const {
    title,
    body,
    users,
    title_tab_one,
    show_tab_one,
  } = mergedData.prismicDigitalPitchDeck.data

  return (
    <Layout>
      <SEO title={title.text} />
      <EmailMask users={users}>
        <DeckNav uid={uid} {...mergedData.prismicDigitalPitchDeck.data} />
        <PageTransition>{body && <SliceZone slices={body} />}</PageTransition>
        {show_tab_one === "true" && (
          <PitchFooter
            nextLink={{
              name: title_tab_one.text,
            }}
            uid={uid}
          />
        )}
      </EmailMask>
    </Layout>
  )
}

export default DigitalPitchDeck

export const pageQuery = graphql`
  query pitchBySlug($uid: String!) {
    prismicDigitalPitchDeck(uid: { eq: $uid }) {
      uid
      data {
        title {
          text
        }
        users {
          email {
            text
          }
        }
        show_tab_one
        show_tab_two
        show_tab_three
        show_tab_four
        title_tab_one {
          text
        }
        title_tab_two {
          text
        }
        title_tab_four {
          text
        }
        title_tab_three {
          text
        }
        body {
          ... on PrismicDigitalPitchDeckBodyPageIntro {
            id
            slice_type
            primary {
              subheading {
                text
              }
              heading {
                text
              }
            }
          }
          ... on PrismicDigitalPitchDeckBodyTwoUpImage {
            id
            slice_type
            primary {
              small_image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 800, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              large_image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1800, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          ... on PrismicDigitalPitchDeckBodyHeadline {
            id
            slice_type
            primary {
              text {
                html
              }
              size
            }
          }
          ... on PrismicDigitalPitchDeckBodyTextBlock {
            id
            slice_type
            primary {
              heading {
                text
              }
              text {
                html
              }
            }
          }
          ... on PrismicDigitalPitchDeckBodyKeyRequirements {
            id
            slice_type
            primary {
              heading {
                text
              }
            }
            items {
              requirement {
                text
              }
            }
          }
          ... on PrismicDigitalPitchDeckBodyVideoTeaser {
            id
            slice_type
            primary {
              heading {
                text
              }
              video {
                url
              }
              link_text {
                text
              }
            }
          }
          ... on PrismicDigitalPitchDeckBodyImageCarousel {
            id
            slice_type
            items {
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1000, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              video {
                url
              }
              is_video
            }
          }
          ... on PrismicDigitalPitchDeckBodyTechStack {
            id
            slice_type
            primary {
              heading {
                text
              }
              text {
                html
              }
              grid_size
            }
            items {
              logo {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 500, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              name {
                text
              }
              description {
                text
              }
            }
          }
          ... on PrismicDigitalPitchDeckBodyServices {
            id
            slice_type
            primary {
              heading {
                text
              }
            }
            items {
              service {
                text
              }
              description {
                html
              }
            }
          }
          ... on PrismicDigitalPitchDeckBodyStaffBios {
            id
            slice_type
            primary {
              heading {
                html
              }
            }
            items {
              bio {
                document {
                  ... on PrismicStaffBio {
                    id
                    data {
                      name {
                        text
                      }
                      position {
                        text
                      }
                      bio {
                        html
                      }
                      image {
                        localFile {
                          childImageSharp {
                            fluid(maxWidth: 960, quality: 90) {
                              ...GatsbyImageSharpFluid_withWebp
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicDigitalPitchDeckBodyCaseStudies {
            id
            slice_type
            items {
              case_study {
                document {
                  ... on PrismicCaseStudy {
                    id
                    tags
                    data {
                      video {
                        url
                      }
                      logo {
                        localFile {
                          childImageSharp {
                            fluid(maxWidth: 1800, quality: 90) {
                              ...GatsbyImageSharpFluid_withWebp
                            }
                          }
                        }
                      }
                      image {
                        localFile {
                          childImageSharp {
                            fluid(maxWidth: 1800, quality: 90) {
                              ...GatsbyImageSharpFluid_withWebp
                            }
                          }
                        }
                      }
                      description {
                        html
                      }
                      reverse_text
                      background_color
                    }
                  }
                }
              }
            }
          }
          ... on PrismicDigitalPitchDeckBodyImage {
            id
            slice_type
            primary {
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 2400, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              horizontal_wrap
            }
          }
          ... on PrismicDigitalPitchDeckBodyUiVideo {
            id
            slice_type
            primary {
              video {
                url
              }
              prototype_link {
                url
              }
            }
          }
          ... on PrismicDigitalPitchDeckBodyTextWithBackground {
            id
            slice_type
            primary {
              background_colour
              text_color
              text {
                html
              }
            }
          }
          ... on PrismicDigitalPitchDeckBodyImageText {
            id
            slice_type
            primary {
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 2400, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              text {
                html
              }
              reverse_layout
            }
          }
          ... on PrismicDigitalPitchDeckBodyPricingTable {
            id
            slice_type
            primary {
              terms {
                html
              }
              intro {
                html
              }
            }
            items {
              line_title {
                text
              }
              line_price {
                text
              }
              sale_price {
                text
              }
              description {
                html
              }
            }
          }
          ... on PrismicDigitalPitchDeckBodyEnquiryPrompt {
            id
            slice_type
            primary {
              heading {
                text
              }
            }
          }
          ... on PrismicDigitalPitchDeckBodySpacer {
            id
            slice_type
            primary {
              size
            }
          }
          ... on PrismicDigitalPitchDeckBodyStatistics {
            id
            slice_type
            items {
              number
              prefix
              suffix
              text {
                html
              }
            }
          }
        }
      }
    }
  }
`
