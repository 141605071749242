import React from "react"
import { Router } from "@reach/router"
import UnpublishedPage from "../components/unpublishedPreview"

const App = () => {
  return (
    <>
      <Router>
        <PublicRoute path="/app">
          <UnpublishedPage path="/unpublished" />
        </PublicRoute>
      </Router>
    </>
  )
}
function PublicRoute(props) {
  return <div>{props.children}</div>
}

export default App
