import React from "react"
import PitchTemplate from "../templates/digitalPitchDeck"

export const UnpublishedPage = props => {
  const previewData = window.__PRISMIC_PREVIEW_DATA__

  // => Perform any logic from previewData to determine the correct page or template component to use.
  const pageType = Object.keys(previewData)[0]

  const PageTemplate = () => {
    switch (pageType) {
      case "prismicDigitalPitchDeck":
        return <PitchTemplate {...props} {...previewData} />
      default:
        return null
    }
  }

  return <PageTemplate />
}

export default UnpublishedPage
